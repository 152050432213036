<template>
  <div>
    <el-tag v-if="workers_status === 'idle'" type="success">
      <span v-if="lang_status == 'en'"> Idle</span>
      <span v-else>{{ workers_status | worker_status_zhcn }}</span>
    </el-tag>
    <el-tag v-if="workers_status === 'busy'" type="warning"
      ><span v-if="lang_status == 'en'"> Busy</span>
      <span v-else>{{ workers_status | worker_status_zhcn }}</span></el-tag
    >
    <el-tag v-if="workers_status === 'normal'" type="info"
      ><span v-if="lang_status == 'en'"> Normal</span>
      <span v-else>{{ workers_status | worker_status_zhcn }}</span></el-tag
    >
    <el-tag v-if="workers_status === 'allbusy'" type="warning"
      ><span v-if="lang_status == 'en'"> All Busy</span>
      <span v-else>{{ workers_status | worker_status_zhcn }}</span></el-tag
    >
    <el-tag v-if="workers_status === 'dead'" type="warning"
      ><span v-if="lang_status == 'en'"> No worker</span>
      <span v-else>{{ workers_status | worker_status_zhcn }}</span></el-tag
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WorkerStatusTag",
  props: {
    workers_status: String,
  },
  computed: {
    ...mapState({
      lang_status: (state) => state.lang_status,
    }),
  },
};
</script>
