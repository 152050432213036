<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'UsersManagement' }">{{
        $t("buttons.computation_nodes")
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("computation.nodes") }}</p>
        </div>
      </div>

      <div class="page-block-content">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="handleOpenCreateNode"
            >{{ $t("computation.create") }}</el-button
          >
        </div>
        <div></div>
        <div class="results-part">
          {{ $t("computation.nodes_all") }}: {{ n_nodes }}
          <el-button
            type="text"
            icon="el-icon-refresh"
            @click="refreshNodesList"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="page-content-fill" v-loading="content_in_processing">
      <el-table :data="nodes_list" stripe style="width: 100%">
        <el-table-column
          prop="id"
          fixed="left"
          :label="$t('computation.id')"
          width="50"
        >
        </el-table-column>
        <el-table-column
          :label="$t('computation.node_name')"
          width="200"
          prop="name"
        >
        </el-table-column>
        <el-table-column :label="$t('computation.node_ip')" width="150">
          <template slot-scope="scope">
            <code>{{ scope.row.ip }}</code>
          </template>
        </el-table-column>
        <el-table-column :label="$t('computation.node_port')" width="80">
          <template slot-scope="scope">
            <code>{{ scope.row.port }}</code>
          </template>
        </el-table-column>
        <el-table-column :label="$t('computation.node_token')" width="300">
          <template slot-scope="scope">
            <el-input
              placeholder="API Token"
              v-model="nodes_list[scope.$index].token"
              size="small"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column :label="$t('computation.node_livestatus')" width="100">
          <template slot-scope="scope">
            <WorkerStatusTag
              v-if="scope.row.id in nodes_status"
              :workers_status="nodes_status[scope.row.id].workers_status"
            />
            <el-tag v-else type="danger">
              {{ $t("computation.node_null") }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('buttons.operation')"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleNodeUpdate(nodes_list[scope.$index])"
              :disabled="nodes_list[scope.$index].token.length === 0"
              >{{ $t("computation.node_token_update") }}</el-button
            >
            <el-popconfirm
              :title="
                $t('computation.del_id_preffix') +
                scope.row.id +
                $t('computation.del_id_suffix')
              "
              :confirmButtonText="$t('buttons.confirm')"
              :cancelButtonText="$t('buttons.cancel')"
              @onConfirm="handleDeleteNode(scope.row.id)"
            >
              <el-button
                slot="reference"
                size="mini"
                type="danger"
                style="margin-left: 10px"
                :disabled="scope.row.status === 'running'"
                >{{ $t("buttons.delete") }}</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :total="n_nodes"
        :page-size="n_nodes_per_page"
        :current-page.sync="n_page"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="$t('computation.create')"
      :visible.sync="create_node_dialogue_visible"
      width="50%"
      v-loading="validating_token"
      element-loading-text="$t('computation.try_connect')"
    >
      <el-form :model="form" :rules="form_rules" ref="nodeForm">
        <el-form-item :label="$t('computation.node_name')" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('computation.node_ip')" prop="ip">
          <el-input v-model="form.ip" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('computation.node_port')" prop="port">
          <el-input
            v-model="form.port"
            :controls="false"
            type="number"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('computation.node_token')" prop="token">
          <el-input v-model="form.token" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="create_node_dialogue_visible = false">{{
          $t("buttons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleCreateNode">{{
          $t("buttons.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import moment from "moment";
import WorkerStatusTag from "@/components/WorkerStatusTag";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "ComputationNodes",
  components: { WorkerStatusTag },
  data() {
    return {
      nodes_list: [],
      n_nodes: 0,
      n_nodes_per_page: 8,
      n_page: 1,

      node_id_show: "",
      create_node_dialogue_visible: false,
      form: {
        name: "a",
        ip: "10.0.0.219",
        port: 8000,
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjbG91ZC1kbmRjIn0.71qbufGAfzZSwVfHbI9Awj9NvgtJ3n6ukOnClOfgdHo",
      },
      validating_token: false,
      form_rules: {
        name: [
          {
            required: true,
            message: this.$t("computation.node_input_name"),
            trigger: "change",
          },
        ],
        ip: [
          {
            required: true,
            message: this.$t("computation.node_input_ip"),
            trigger: "change",
          },
        ],
        port: [
          {
            required: true,
            message: this.$t("computation.node_input_port"),
            trigger: "change",
          },
        ],
        token: [
          {
            required: true,
            message: this.$t("computation.node_input_token"),
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    node_id_show(nv) {
      this.refreshNodesList();
      console.log(nv);
    },
  },
  beforeMount() {
    this.switchDefaultPage("/dashboard/computation-nodes");
  },
  computed: {
    ...mapGetters(["headers4reqs", "user_dict", "access_token"]),
    ...mapState({
      user_name: (state) => state.user.statistics.name,
      user_statistics: (state) => state.user.statistics,
      lang_status: (state) => state.lang_status,
      admin_name: (state) => state.admin_name,
      app_name: (state) => state.app_name,
      nodes_status: (state) => state.nodes_status,
    }),
  },
  async mounted() {
    this.refreshNodesList();
  },
  destroyed() {},
  methods: {
    ...mapActions(["switchDefaultPage", "clearNodeInfo"]),

    node_id2name(i) {
      if (i in this.user_dict) {
        return this.user_dict[i];
      } else {
        return this.$t("reminders.loading");
      }
    },
    async getNodeList() {
      var params = {
        skip: (this.n_page - 1) * this.n_nodes_per_page,
        limit: this.n_nodes_per_page,
      };
      if (this.node_id_show !== "") {
        params.node_id = this.node_id_show;
      }
      await this.$get_api(
        apiURL.computation.list,
        null,
        this.headers4reqs,
        params
      ).then((res) => {
        this.n_nodes = res.n_nodes;
        this.nodes_list = res.nodes;
      });
    },
    async handlePageChange(page_id) {
      console.log(page_id);
      await this.getNodeList();
    },
    async refreshNodesList() {
      this.n_page = 1;
      this.content_in_processing = true;
      await this.getNodeList();
      this.content_in_processing = false;
    },
    async handleDeleteNode(node_id) {
      if (node_id !== null) {
        await this.$delete_api(
          apiURL.computation.node.replace("node_id", node_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.refreshNodesList();
          return res;
        });
        this.clearNodeInfo(node_id);

        //清理本地缓存
      }

      return node_id;
    },
    handleNodeUpdate(node) {
      this.$put_api(
        apiURL.computation.node.replace("node_id", node.id),
        node,
        null,
        this.headers4reqs
      )
        .then((res) => {
          this.showApiMsg(
            this.$t("computation.token_update_success"),
            "success"
          );
          this.refreshNodesList();
          return res;
        })
        .catch(this.apiErrHandler);
    },
    handleOpenCreateNode() {
      this.create_node_dialogue_visible = true;
    },
    apiErrHandler(e) {
      this.validating_token = false;
      console.log("create failed: ", e.response.status);
      if (e.response.status === 407) {
        this.showApiMsg(this.$t("computation.token_existed"), "error");
      } else if (e.response.status === 408) {
        this.showApiMsg(this.$t("computation.token_unaccessible"), "error");
      } else if (e.response.status === 409) {
        this.showApiMsg(this.$t("computation.token_port_closed"), "error");
      } else if (e.response.status === 410) {
        this.showApiMsg(this.$t("computation.token_error"), "error");
      }
    },
    async handleCreateNode() {
      this.$refs["nodeForm"].validate((valid) => {
        if (valid) {
          this.validating_token = true;
          this.$post_api(
            apiURL.computation.new,
            this.form,
            null,
            this.headers4reqs
          )
            .then((res) => {
              console.log("res.status_code is ", res.status_code);
              this.validating_token = false;
              this.showApiMsg(
                this.$t("computation.node_create_success"),
                "success"
              );
              this.refreshNodesList();
              this.create_node_dialogue_visible = false;
            })
            .catch(this.apiErrHandler);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  filters: {
    toLocalTime(t) {
      return moment.utc(t).local();
    },
    formatLocalTime(t) {
      return t.format("YYYY-MM-DD HH:mm:ss");
    },
  },
  //
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>
